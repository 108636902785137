<template>
  <main class="bg-light">
    <div>
      <keep-alive>
        <top-nav/>
      </keep-alive>
    </div>
    <div class="mb-3">
      <router-view
          v-on:load="load"
      />
    </div>
    <div v-if="pending">
      <b-spinner
          size="lg"
          variant="danger"
      />
    </div>
    <div v-else>
      <b-container>
        <b-row>
          <draggable
              class="list-group"
              tag="ul"
              v-model="list"
              v-bind="dragOptions"
              @start="drag = true"
              @end="drag = false"
              v-on:change = "save"
              style="width:100%"
          >
            <transition-group type="transition" :name="!drag ? 'flip-list' : null">
              <li
                  class="list-group-item"
                  v-for="element in dragList"
                  :key="element._id"
              >
                <i
                    :class="
                      element.fixed ? 'fa fa-anchor' : 'glyphicon glyphicon-pushpin'
                    "
                    @click="element.fixed = !element.fixed"
                    aria-hidden="true"
                ></i>
                <router-link :to="`/faq/${element._id}`">
                  {{ element.name }}
                </router-link>
              </li>
            </transition-group>
          </draggable>
        </b-row>
        <b-row>
          <div class="d-flex justify-content-end m-2"
               style="width:100%">
            <b-button
                to="/faq/new"
                variant="danger"
                size="sm"
                squared
            >
              <font-awesome-icon icon="plus"/> 등록
            </b-button>
          </div>
        </b-row>
      </b-container>
      <b-container class="mt-4">
        <b-row class="text-center">
          <b-col>숨김 리스트</b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-table-simple small responsive="" bordered class="bg-white">
              <b-thead>
                <b-tr>
                  <b-th>
                    TITLE
                  </b-th>
                  <b-th>
                    등록 ID
                  </b-th>
                  <b-th>
                    DATE
                  </b-th>
                  <b-th>
                    상태
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr
                    v-for="q in hideList"
                    :key="q._id"
                >
                  <b-td class="text-center">
                    <div class="d-block">
                      <p
                          class="text-truncate my-1"
                          style="font-size: 0.8rem"
                      >
                        <router-link :to="`/faq/${q._id}`">
                          {{ q.title }}
                        </router-link>
                      </p>
                    </div>
                  </b-td>
                  <b-td>
                    <div>
                      {{ q.regUserId }}
                    </div>
                  </b-td>
                  <b-td>
                    <div>
                      {{ new Date(q.regDate).toLocaleString() }}
                    </div>
                  </b-td>
                  <b-td>
                    <div>
                      {{q.use ? '활성화' : '숨김'}}
                    </div>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </main>
</template>

<script>
  import Draggable from 'vuedraggable';
  export default {
    name: "faq",
    components: {
      draggable: Draggable
    },
    data: () => ({
      pending: false,
      drag: false,
      list: [],
      hideList: []
    }),
    created () {
      (async () => {
        await this.load()
      })()
    },
    methods: {
      async load () {
        this.pending = true
        try {
          const {data} = await this.axios({
            url: `/support/faq`
          })
          const {result, list, hideList} = data
          if (result) {
            this.list = list
            this.hideList = hideList
          }
        } catch (e) {
        }
        this.pending = false
      },
      async save () {
        try {
          const {data} = await this.axios({
            url: '/support/faq',
            method: 'post',
            data: {
              list: this.list
            }
          })

          const {result} = data
          if(result) {
            this.$bvToast.toast(`순서가 변경되었습니다.`, {
              title: 'SUCCESS',
              autoHideDelay: 1000,
              variant: 'success',
              appendToast: false
            })
          }
        } catch (e) {
          console.error(e)
        }
      }
    },
    computed: {
      dragList () {
        if (this.list === undefined) return
        return this.list.map(item => {
          return {_id: item._id, name: item.title, order: item.ord};
        })
      },
      dragOptions() {
        return {
          animation: 200,
          group: "description",
          disabled: false,
          ghostClass: "ghost"
        };
      }
    }
  }
</script>

<style scoped>
  main {
    min-height: 100vh;
  }
  th, td {
    vertical-align: middle;
    font-size: 0.8rem;
  }
  tr.active {
    color: red;
    font-weight: bold;
    background-color: #F5F5F5;
  }

  .button {
    margin-top: 35px;
  }

  .flip-list-move {
    transition: transform 0.5s;
  }

  .no-move {
    transition: transform 0s;
  }

  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }

  .list-group {
    min-height: 20px;
  }

  .list-group-item {
    cursor: move;
  }

  .list-group-item i {
    cursor: pointer;
  }
</style>
